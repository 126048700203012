
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import Table from "@/components/Table.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import moment from "moment";
import FacilityModule from "@/store/facility";
import AuthModule from "@/store/auth";
import FacilityService from "@/services/facilities";
import CheckCommonLogin from "@/utils/CheckCommonLogin";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import Editor from "@tinymce/tinymce-vue";
import { getS3Url } from "@/plugins/s3";
import { AnnouncementData } from "@/@types/management";

@Component({
  name: "HospitalAnnouncementList",
  components: {
    HospitalHeader,
    Table,
    ManagementFooter,
    Button,
    FormSet,
    Editor,
  },
})
export default class HospitalAnnouncementList extends CheckCommonLogin {
  private get hospitalId() {
    return FacilityModule.hospitalId;
  }
  private get me() {
    return AuthModule.me;
  }
  private tableLoading = false;
  private announcementHeaders = [
    { text: "公開開始日", value: "publish_start_at", width: "150px", align: "start", sortable: false },
    { text: "お知らせタイトル", value: "title", sortable: false },
  ];
  private showLabel = false;
  private announcementList: AnnouncementData[] = [];
  private unreadAnnouncementList: AnnouncementData[] = [];

  private isUnread(item: AnnouncementData) {
    if (this.unreadAnnouncementList.some((unread) => unread.id === item.id)) {
      return true;
    }
    return false;
  }

  private async linkToAnnouncementDetail(item: AnnouncementData) {
    // pdfの場合は別タブで開く
    if (item.body_type === "PDF") {
      const key = item.pdf_s3_url?.split(`${process.env.VUE_APP_S3_BUCKET_NAME}/`).pop();
      if (!key) return;
      FacilityService.readAnnouncement(this.me!.id, item.id);
      await this.getAnnouncementList();
      this.getUnreadAnnouncementList();
      window.open(process.env.VUE_APP_INFO_URL + "/" + key, "_blank");
      return;
    }
    this.$router.push({ name: "HospitalAnnouncementDetail", params: { id: item.id } });
  }

  private file = null;

  private form: any = {
    title: "",
    published_start_at: "",
    published_end_at: "",
    is_file: false,
    updated_by: this.me?.id,
    content: "",
  };

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async mounted() {
    this.tableLoading = true;
    await this.getAnnouncementList();
    this.getUnreadAnnouncementList();
    this.tableLoading = false;
  }

  private get dateTimeCheck() {
    const start = moment(this.form.publish_start_at);
    const end = moment(this.form.publish_end_at);
    return end.isAfter(start);
  }

  private displayDateFactory(date: string, add9?: boolean) {
    if (!add9) {
      //YYYY年MM月DD日というフォーマットに変換
      moment.locale("ja");
      return moment(date).format("YYYY年MM月DD日");
    } else {
      return moment(date).format("YYYY/MM/DD HH:mm");
    }
  }

  private async getUnreadAnnouncementList() {
    const res = await FacilityService.getAnnouncements({
      cognito_id: this.me?.id,
      read_status: "unread",
      facility_id: this.hospitalId,
    });
    this.unreadAnnouncementList = res.data.data;
  }

  private async getAnnouncementList() {
    const res = await FacilityService.getAnnouncements({ facility_id: this.hospitalId });
    this.announcementList = res.data.data;
    // 公開開始日以前のものと公開終了日以降のものを除外
    this.announcementList = this.announcementList.filter((announcement) => {
      const start = moment(announcement.publish_start_at);
      const end = moment(announcement.publish_end_at);
      if (!announcement.publish_end_at) return start.isBefore(moment());
      return start.isBefore(moment()) && end.isAfter(moment());
    });
    // 降順にソート
    this.announcementList.sort(function (x, y) {
      var firstDate = new Date(x.publish_start_at),
        SecondDate = new Date(y.publish_start_at);

      if (firstDate < SecondDate) return 1;
      if (firstDate > SecondDate) return -1;
      return 0;
    });
  }
}
